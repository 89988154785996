<template>
  <div class="container" :style="setBgColor()">
    <div class="logo-item" @click="backHome">
      <img :src="require(`@/static/images/${logoPath}`)" class="logo-image">
    </div>
    <div class="right-panel">
      <el-menu :default-active="activeIndex" :text-color="textColor" :active-text-color="activeTextColor" :background-color="backgroundColor" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/index">首页</el-menu-item>
        <el-menu-item index="/member/index/1">实时信息</el-menu-item>
        <el-menu-item index="/member/index/2">我的推荐</el-menu-item>
        <el-menu-item index="/member/index/3">经典展播</el-menu-item>
        <el-menu-item index="/member/index/4">健康体育</el-menu-item>
        <el-menu-item index="/member/index/5">社区风貌</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      activeIndex: ""
    }
  },
  computed: {
    textColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return '#303133'
      }
    },
    activeTextColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return ''
      }
    },
    logoPath() {
      if (this.internal) {
        return 'bg_logo.png'
      } else {
        return 'logo.png'
      }
    },
    backgroundColor() {
      if (this.internal) {
        return '#194D8F'
      } else {
        return ''
      }
    },
    ...mapGetters(['path', 'internal', 'isAuthenticated', 'userInfo'])
  },
  methods: {
    handleSelect() {},
    backHome() {
      this.$router.push({ path: '/index' });
    },
    setBgColor() {
      return {
        backgroundColor: this.internal ? '#194D8F' : '#fff',
      }
    },
    setColor() {
      return {
        color: this.internal ? '#ffffff' : '#303133'
      }
    }
  },
  mounted() {
    this.activeIndex = this.path;
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
    }
  }
}

</script>

<style lang="scss" scoped>
.el-menu-item:hover {
  background-color: transparent !important;
}
::v-deep .el-dropdown-menu__item, .el-menu-item {
  font-size: 1.8rem;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal>.el-menu-item {
  margin: 0 3rem !important;
}
::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.25rem solid #409EFF;
  color: #303133;
}
.container {
  padding: 0 10%;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-item {
    cursor: pointer;
    .logo-image {
      width: 12rem;
      object-fit: cover;
    }
  }
  .right-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .user-item {
      margin-left: 2.5rem;
      font-size: 1.8rem;
    }
  }
}
</style>
