<template>
  <div class="container">
    <div class="upper">
      <el-image :src="require('@/static/images/logo.png')" class="logo-image"></el-image>
    </div>
    <div style="background-color:#333333;">
      <div class="box">
        <div class="mb10" style="margin-top: 1rem">©成都市社区建设与文化发展促进中心</div>
        <div class="mb10">邮箱  niz510@163.com</div>
        <div class="mb10">由成都仁瀚提供网站运营技术支持及版权所有</div>
        <div class="mb10 pointer filings" @click="jump">蜀ICP备2023025668号</div>
      </div>
    </div>
    <div class="lower">
      <div class="lower-item">
        <div class="title-item">关于我们</div>
        <div class="title-item">联系我们</div>
        <div class="title-item">广告业务</div>
        <div class="title-item">手机版</div>
        <div class="title-item">隐私保护</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jumpPage(path, scrollTo=false) {
      if (scrollTo) {
        window.scrollTo(0, 0);
      }
      this.$router.push({ path: path })
    },
    jump() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-image {
  width: 12rem;
  object-fit: cover;
}
.filings:hover {
  color: #1896E8;
  text-decoration: underline;
}
.container {
  width: 100%;
  //height: 36rem;
  .box {
    width: 50%;
    margin: 0 auto;
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .upper {
    width: 100%;
    height: 24rem;
    background-color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lower {
    width: 100%;
    height: 5rem;
    background-color: #031833;
    position: relative;
    .lower-item {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: 50%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: white;
      font-size: 1.2rem;
      font-weight: 500;
      .title-item {
        padding: 0 1.5rem;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
</style>
