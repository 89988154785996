<template>
  <div>
    <Headers></Headers>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Headers from "@/components/Headers.vue";
export default {
  data() {
    return {}
  },
  computed: {
  },
  created() {
  },
  components: {
    Footer,
    Headers
  },
  methods: {
  },
}

</script>

<style scoped>

</style>
