const state = {
    singleReports: [],
}

const mutations = {
    SET_SINGLE_REPORTS: (state, singleReports) => {
        state.singleReports = singleReports
    }
}

const actions = {
    addArray({ commit }) {
        let new_array = state.singleReports;
        new_array.push({
            BillCode: "",
            GoodName: "",
            GoodType: "",
            GoodNum: 1,
            GoodPrice: 1,
            TotalPrice: 1,
            remarks: ''
        })
        commit('SET_SINGLE_REPORTS', new_array)
    },
    removeArray({ commit }) {
        let new_array = state.singleReports;
        new_array.pop();
        commit('SET_SINGLE_REPORTS', new_array)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
