import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router';
import './static/style/common.css'
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    setRemPc();
    window.addEventListener('resize', setRemPc);
  }
}).$mount('#app')


//rem计算
function setRemPc() {
  const whdef = 10 / 1920;// 表示1920的设计图,使用100PX的默认值
  const bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}
