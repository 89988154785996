import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home/index.vue';
import store from '@/store/index.js';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/index',
            component: Home,
            children: [
                {
                    path: 'index',
                    name: 'index',
                    component: () => import(/* webpackChunkName: "home" */ '../views/layout/index.vue'),
                    meta: {
                        title: '仁瀚科技',
                        internal: false
                    }
                },
                {
                    path: 'news/:id',
                    name: 'News',
                    component: () => import(/* webpackChunkName: "home" */ '../views/News/index.vue'),
                    meta: {
                        title: '新闻详情',
                        internal: false
                    }
                },
                {
                    path: 'member',
                    name: 'member',
                    component: () => import(/* webpackChunkName: "home" */ '../views/member/layout.vue'),
                    meta: {
                        title: '资讯中心',
                        internal: false
                    },
                    children: [
                        {
                            path: 'index/:status',
                            name: 'index',
                            component: () => import(/* webpackChunkName: "home" */ '../views/member/index/common.vue'),
                            meta: {
                                title: '资讯中心',
                                internal: false
                            }
                        }
                    ]
                }
            ]
        }
    ]
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '默认标题';
    store.dispatch('webSite/changePath', to.path);
    store.dispatch('webSite/headerRecast', to.meta.internal || false)
    next()
});


export default router;
