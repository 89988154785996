const state = {
    path: undefined,
    internal: false,
    shopID: undefined,
    TenantId: undefined
}

const mutations = {
    SET_PATH: (state, path) => {
        state.path = path
    },
    SET_INTERNAL: (state, internal) => {
        state.internal = internal
    },
    SET_SHOP_ID: (state, id) => {
        state.shopID = id
    },
    SET_TENANT_ID: (state, tenantId) => {
        state.TenantId = tenantId
    }
}

const actions = {
    changePath({ commit }, path) {
        commit('SET_PATH', path)
    },
    headerRecast({ commit }, internal) {
        commit('SET_INTERNAL', internal)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
